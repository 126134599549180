import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import Row from "./row";
interface props {
  header?: string;
  text?: string;
  children: any;
  leftArrow: any;
  rightArrow: any;
  interval?: number;
}

const SimpleTestimonialCarousel: React.FC<props> = (props) => {
  let interval = props.interval ?? 3000;
  return (
    <>
      {props.header && <h1 dangerouslySetInnerHTML={{__html: props.header}} />}
      {props.text && (
        <p
          style={{textAlign: "center"}}
          dangerouslySetInnerHTML={{__html: props.text}}
        />
      )}

      <Carousel
        autoPlay={true}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        infiniteLoop={true}
        interval={interval}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <div
              onClick={onClickHandler}
              style={{
                position: "absolute",
                left: "50%",
                bottom: "0px",
                cursor: "pointer",
                zIndex: 1000,
              }}
            >
              {props.leftArrow}
            </div>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <div
              onClick={onClickHandler}
              style={{
                position: "absolute",
                right: "50%",
                bottom: "0px",
                cursor: "pointer",
                zIndex: 1000,
              }}
            >
              {props.rightArrow}
            </div>
          )
        }
      >
        {props.children}
      </Carousel>
    </>
  );
};
export default SimpleTestimonialCarousel;
