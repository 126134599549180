import {StaticImage} from "gatsby-plugin-image";
import "starkeydigital/wwwroot/css/styles.css";
import "../styles/site.scss";
import React from "react";
import Container from "starkeydigital/React/Components/container";
import Footer from "./footer";
import Row from "starkeydigital/React/Components/row";
import Column from "starkeydigital/React/Components/column";
import Nav from "./nav";
import styled from "@emotion/styled";
import LetsWork from "./letswork";
import Testimonials from "./testimonials";
import {Helmet} from "react-helmet";
interface props {
  titleText: string;
  titleText2: string;
  slogan: string;
  imageUrl?: string;
  includeContact?: boolean;
  includeTestimonials?: boolean;
  testimonialsBackground?: string;
  includeRandomTestimonial?: boolean;
  keywords?: string;
}

const Grid = styled.div`
  display: grid;
  width: 100%;
  height: 678px;
`;

const MainText = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: bold;
  /* font-size: 64px; */
  font-size: 48px;
  line-height: 95.6%;
  letter-spacing: -0.025em;
  color: #ffffff;

  @media (max-width: 786px) {
    font-weight: bold;
    font-size: 48px;
    line-height: 95.6%;
  }
`;

const SubText = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  letter-spacing: 0.025em;

  color: #ffffff;
`;

const Layout: React.FC<props> = (props) => {
  let includeContact = props.includeContact ?? true;
  let includeTestimonials = props.includeTestimonials ?? true;
  let includeRandomTestimonial = props.includeRandomTestimonial ?? true;

  let headerTextAlign: any = props.imageUrl ? "center" : "left";
  let rowAlign: any = props.imageUrl ? "center" : "start";

  const defaultHeader =
    "Welcome to atd partners. Our aim is to develop and release the potential of individuals, teams and the organisations they work in. We are experts in leadership coaching and women’s development &amp; diversity.We will be delighted to share our experience with you. Developing Leaders and Teams. Around the world. We provide leadership and coaching programmes…";

  const defaultKeywords =
    "executive, coaching, mentoring, leadership, career, talent, management, talent management, retention, online coaching, online, diversity, inclusion, diversity and inclusion, women, women's leadership, senior leadership, senior, senior leader development, atd, atd partners, auditors, auditors L&D, lawyers, coaching laywers, digital, digital coaching ";

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.titleText}</title>
        <meta property="og:title" content={props.titleText} />
        {props.slogan ? (
          <meta name="description" content={props.slogan} />
        ) : (
          <meta name="description" content={defaultHeader}></meta>
        )}

        {props.slogan ? (
          <meta property="og:description" content={props.slogan} />
        ) : (
          <meta name="og:description" content={defaultHeader}></meta>
        )}

        <meta property="og:type" content="article" />
        <meta name="author" content="atd partners ltd" />
        <meta property="og:site_name" content="atd partners" />
        <link rel="canonical" href="https://atdpartners.co.uk/"></link>
        <meta property="og:url" content="https://atdpartners.co.uk/" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />

        <meta property="og:image" content="/favicon-16x16.png" />

        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#02578c" />
        <meta name="msapplication-TileColor" content="#2d89ef" />
        <meta name="theme-color" content="#ffffff"></meta>

        {props.keywords ? (
          <meta name="keywords" content={props.keywords} />
        ) : (
          <meta name="keywords" content={defaultKeywords} />
        )}
      </Helmet>

      <Grid>
        <StaticImage
          alt="hero"
          src="../images/hero.png"
          style={{gridArea: "1/1"}}
          layout="fullWidth"
          quality={100}
          imgStyle={{objectPosition: "left"}}
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            display: "grid",
          }}
        >
          <Container>
            <Nav />

            {props.imageUrl && (
              <Row justify="center">
                <Column class="justify-content-center">
                  <img
                    width="200px"
                    height="200px"
                    src={props.imageUrl}
                    alt="main-image"
                  />
                </Column>
              </Row>
            )}

            <Row>
              <Column>
                <MainText
                  className="animate__fadeInUp animate__animated"
                  style={{textAlign: headerTextAlign}}
                >
                  {props.titleText}
                  <br />
                  {props.titleText2}
                </MainText>
              </Column>
            </Row>

            <Row justify={rowAlign}>
              <div
                className="col-md-8  animate__fadeInUp animate__animated delay"
                style={{marginTop: "25px"}}
              >
                <SubText
                  dangerouslySetInnerHTML={{__html: props.slogan}}
                  style={{textAlign: headerTextAlign}}
                />
              </div>
            </Row>
          </Container>
        </div>
      </Grid>

      <Container>{props.children}</Container>

      <div style={{marginTop: "100px"}}>
        {/* {includeRandomTestimonial && <RandomQuote />} */}
        {includeTestimonials && (
          <Testimonials background={props.testimonialsBackground} />
        )}
        {includeContact && <LetsWork />}
      </div>

      <Footer />
    </>
  );
};
export default Layout;
