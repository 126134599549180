import React from "react";
interface props {
  class?: string;
  style?: React.CSSProperties;
}
const Column: React.FC<props> = (props) => {
  const className = props.class ?? "col";
  return (
    <div className={className} style={props.style}>
      {props.children}
    </div>
  );
};
export default Column;
