import React, {Dispatch, SetStateAction} from "react";
import Container from "./container";
import Row from "./row";
import {useLocation} from "@reach/router";
interface props {}

interface NavbarContextProps {
  open: Boolean;
  setOpen: Dispatch<SetStateAction<Boolean>>;
}

let defaultValues: NavbarContextProps = {
  open: false,
  setOpen: () => {},
};
const NavbarContext = React.createContext<NavbarContextProps>(defaultValues);

interface DropdownProps {
  title: string;
  route: string;
  className: string;
  activeClassName?: string;
}
const Dropdown: React.FC<DropdownProps> = ({
  title,
  className,
  activeClassName,
  route,
  children,
}) => {
  const location = useLocation();
  let isActive = location.pathname.includes(route);
  return (
    <div
      className={`${className} ${isActive && activeClassName} navbar__dropdown`}
    >
      {title}
      <div className="navbar__dropdown__content">{children}</div>
    </div>
  );
};

const NavItems: React.FC = ({children}) => {
  const context = React.useContext(NavbarContext);
  return (
    <div className={`navbar__items ${context.open && "selected"}`}>
      <div
        className="navbar__items__close"
        onClick={() => context.setOpen(false)}
      >
        X
      </div>
      {children}
    </div>
  );
};

const Logo: React.FC = ({children}) => (
  <div className="navbar__logo">{children}</div>
);

const Hamburger: React.FC = ({children}) => {
  const context = React.useContext(NavbarContext);

  if (children)
    return (
      <div className="navbar__hamburger" onClick={() => context.setOpen(true)}>
        {children}
      </div>
    );
  else return <></>;
};

interface navProps {
  children: any;
  class?: string;
  style?: React.CSSProperties;
}

const Navbar = (props: navProps) => {
  let [open, setOpen] = React.useState<Boolean>(false);
  const value = {open, setOpen};

  return (
    <Container>
      <Row style={props.style}>
        <NavbarContext.Provider value={value}>
          <div className={`navbar__container ${props.class}`}>
            {props.children}
          </div>
        </NavbarContext.Provider>
      </Row>
    </Container>
  );
};

Navbar.NavItems = NavItems;
Navbar.Dropdown = Dropdown;
Navbar.Hamburger = Hamburger;
Navbar.Logo = Logo;

export default Navbar;
