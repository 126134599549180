import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import Container from "starkeydigital/React/Components/container";
import Row from "starkeydigital/React/Components/row";
import styled from "@emotion/styled";
import Column from "starkeydigital/React/Components/column";
import Link from "./link";
import Pages from "../data/pages";

const Email = styled.a`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.025em;
  text-decoration-line: none !important;
  color: #ffffff !important;
  cursor: pointer;
`;

const Phone = styled.a`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.025em;
  cursor: pointer;
  color: #b2b3b6 !important;
`;

const CopyRight = styled.span`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 27px;
  color: #a4a4a2;
  text-align: "center";
  margin-bottom: 40px;
`;

const PrivacyPolicy = styled.span`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 27px;
  color: #a4a4a2;
  cursor: pointer;
  /* text-decoration: underline; */
  margin-left: 10px;
  text-align: "center";
`;

interface props {}
const Footer: React.FC<props> = (props) => {
  const Wrapper = styled.div`
    background: #525252;
    width: 100%;
    height: 100%;
    padding-top: 20px;
  `;

  return (
    <Wrapper>
      <Container>
        <Row style={{marginTop: "40px", marginBottom: "10px"}} class="footer">
          <Column class="col-md-4 mt-md-0 mt-5">
            <Link to="/">
              <img src="/logo.png" alt="logo" width="155px" />
            </Link>
            <br />
          </Column>

          <Column class="col-md-2 col-6 mt-md-0 mt-5">
            <strong>Leadership Development</strong>
            <br />

            {Pages[0].innerPages?.map((page, index) => (
              <React.Fragment key={index}>
                <Link key={index} to={page.url!}>
                  {page.title}
                </Link>
                <br />
              </React.Fragment>
            ))}
          </Column>

          <Column class="col-md-2 col-6 mt-md-0 mt-5">
            <strong>Executive Coaching</strong>
            <br />

            {Pages[1].innerPages?.map((page, index) => (
              <React.Fragment key={index}>
                <Link to={page.url!}>{page.title}</Link> <br />{" "}
              </React.Fragment>
            ))}
          </Column>

          <Column class="col-md-2 col-6 mt-md-0 mt-5">
            <strong>Board Consulting</strong>
            <br />

            {Pages[2].innerPages?.map((page, index) => (
              <React.Fragment key={index}>
                <Link key={index} to={page.url!}>
                  {page.title}
                </Link>{" "}
                <br />{" "}
              </React.Fragment>
            ))}
          </Column>

          <Column class="col-md-2 col-6 mt-md-0 mt-5mt-md-0 mt-5">
            <strong>atd partners</strong>
            <br />

            <Link to={Pages[3].url!}>{Pages[3].title}</Link>
            <br />
            <Link to={Pages[4].url!}>{Pages[4].title}</Link>
            <br />
            <Link to={Pages[5].url!}>{Pages[5].title}</Link>
            <br />
            <a
              href="https://cms.starkeydigital.com/assets/2BE8666E-78BD-4F18-8FB6-B4F620C716B1?download"
              download
            >
              Privacy Policy
            </a>
          </Column>
        </Row>

        <Bar />
        <Row justify="center">
          <CopyRight> 2021 atd partners. All Rights Reserved.</CopyRight>
        </Row>
      </Container>
    </Wrapper>
  );
};
export default Footer;

const Bar = styled.div`
  width: 100%;
  height: 1px;
  background: #ffffff;
  margin: 40px 0;
`;
