import React from "react";
import styled from "@emotion/styled";
import {StaticImage} from "gatsby-plugin-image";
import Link from "./link";

const Grid = styled.div`
  display: grid;
  width: 100vw;
  height: 305px;
`;

const MainText = styled.div`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 45px;
  color: #ffffff;

  /* identical to box height, or 112% */

  letter-spacing: -0.025em;
  text-align: center;

  @media (max-width: 786px) {
    font-weight: 600;
    font-size: 30px;
    line-height: 31px;
  }
`;

const SubText = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  text-align: center;

  letter-spacing: 0.025em;

  color: #ffffff;
`;

interface props {}
const LetsWork: React.FC<props> = (props) => {
  return (
    <Grid>
      <StaticImage
        alt="hero"
        src="../images/hero-bottom.png"
        style={{gridArea: "1/1"}}
        layout="fullWidth"
        quality={100}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "45px",
        }}
      >
        <MainText>Let's get to work.</MainText>

        <SubText>
          If you’re interested in discovering more about us, please don’t
          hesitate to get in touch
        </SubText>

        <Link className="button-secondary button" to="/contact/#contact-form">
          Contact Us
        </Link>
      </div>
    </Grid>
  );
};
export default LetsWork;
