import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import Navbar from "starkeydigital/React/Components/navbar";
import Pages from "../data/pages";
import Link from "./link";

interface props {}
const Nav: React.FC<props> = (props) => {


  return (
    <Navbar style={{marginBottom: "120px"}} class="nav-top">
      <Navbar.Logo>
        <Link to="/">
          <img src="/logo.png" alt="logo" width="155px" />
        </Link>
      </Navbar.Logo>

      <Navbar.Hamburger>
        <StaticImage
          alt="hamburger"
          src="../images/burger.png"
          placeholder="none"
          style={{marginTop: "20px"}}
        />
      </Navbar.Hamburger>

      <Navbar.NavItems>
        {Pages.map((page, index) => {
          if (page.innerPages && page.innerPages.length > 0) {
            return (
              <Navbar.Dropdown
                key={index}
                title={page.title}
                route={page.url!}
                className="navbar__item animate__fadeInDown animate__animated delay"
                activeClassName="selected"
              >
                {page.innerPages.map((innerPage, index) => (
                  <Link
                    key={index}
                    to={innerPage.url!}
                    className="navbar__item animate__fadeInDown animate__animated delay"
                    activeClassName="selected"
                  >
                    {innerPage.title}
                  </Link>
                ))}
              </Navbar.Dropdown>
            );
          }

          return (
            <Link
              key={index}
              to={page.url!}
              className="navbar__item animate__fadeInDown animate__animated delay"
              activeClassName="selected"
            >
              {page.title}
            </Link>
          );
        })}
      </Navbar.NavItems>
    </Navbar>
  );
};
export default Nav;
