import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

interface props {
  to: string;
  className?: string;
  activeClassName?: string;
  children?: any;
}

const Link = (props: props) => {
  function isPage(): boolean {
    var split = props.to.split("#");
    var page = split[0];

    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      if (page == "/" && window.location.pathname.length > 1) {
        console.log(window.location.pathname);
        return false;
      }
      return window.location.href.includes(page);
    }
    return false;
  }

  function getHash(): string | undefined {
    var split = props.to.split("#");
    if (split.length > 1) {
      return split[1];
    }
  }

  function handleClick() {
    console.log(window.location.href);
    console.log(props.to);
    var hash = getHash();
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({behavior: "smooth", block: "start"});
      }
    }
  }

  let hash = getHash();

  return isPage() && hash ? (
    <a
      className={props.className}
      onClick={handleClick}
      tabIndex={0}
      style={{cursor: "pointer"}}
    >
      {props.children}
    </a>
  ) : (
    <AniLink
      cover
      to={props.to}
      direction="right"
      bg="#02578C"
      duration={0.5}
      className={props.className}
      activeClassName={props.activeClassName}
    >
      {props.children}
    </AniLink>
  );
};
export default Link;
