import React, {DetailedHTMLProps} from "react";
interface props {
  style?: React.CSSProperties;
}
const Container: React.FC<props> = (props) => {
  return (
    <div className="container" style={props.style}>
      {props.children}
    </div>
  );
};
export default Container;
