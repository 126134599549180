import React from "react";
import useFetch from "starkeydigital/React/Hooks/useFetch";
import PulseLoader from "react-spinners/PulseLoader";
import SimpleCarousel from "starkeydigital/React/Components/simpleTestimonialCarousel";
import {StaticImage} from "gatsby-plugin-image";
import styled from "@emotion/styled";
import Row from "starkeydigital/React/Components/row";
import Container from "starkeydigital/React/Components/container";

interface props {
  background?: string;
}

interface Data {
  data: Testimonial[];
}
interface Testimonial {
  id: number;
  date_created: Date;
  date_updated: Date;
  endorsment: string;
}

const Text = styled.div`
  font-family: Poppins;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.025em;
  max-width: 1200px;
  text-align: center;
  margin: auto;
  color: #02578c;
`;

const Testimonials: React.FC<props> = (props) => {
  let background = props.background ?? "#FFFFFF";
  const {data, loading, error} = useFetch<Data>(
    "https://cms.starkeydigital.com/items/atd_team_member_endorsment",
  );

  if (error) return <></>;
  if (data) {
    let count = Math.ceil(data.data.length / 10) * 10;

    if (count < 100) count = 100;

    let text = `${count}+ Testimonials`;
    return (
      <div
        className="testimonial-wrapper"
        style={{
          background: background,
        }}
      >
        <SimpleCarousel
          header="Our clients love <br/> what we do"
          interval={10000}
          text={text}
          leftArrow={
            <StaticImage src="../images/right.png" alt="right arrow" />
          }
          rightArrow={<StaticImage src="../images/left.png" alt="left arrow" />}
        >
          {data.data.map((testimonial, index) => (
            <Text key={index}>
              <span
                dangerouslySetInnerHTML={{__html: testimonial.endorsment}}
              />
            </Text>
          ))}
        </SimpleCarousel>
      </div>
    );
  }
  return <PulseLoader />;
};
export default Testimonials;
