import {NavPage} from "starkeydigital/React/@types/interfaces";

//Titles

let overview: NavPage = {
  title: "Overview",
  url: "/who-are-we/",
};

let executiveCoaching: NavPage = {
  title: "Executive Coaching",
  url: "/executive/",
};

let leaderShipProgrammes: NavPage = {
  title: "Leadership Development",
  url: "/leadership/",
};

//Pages

let aboutUs: NavPage = {
  title: "About us",
  url: `/#about-us`,
};

let dubai: NavPage = {
  title: "Dubai and Gulf States",
  url: `${overview.url}dubai/`,
};

let worldWide: NavPage = {
  title: "Worldwide",
  url: `/#map`,
};

let leaderShipSeniorLeaders: NavPage = {
  title: "Senior Leaders",
  url: `${leaderShipProgrammes.url}senior-leaders/`,
};

let womensLeadershipProgrammes: NavPage = {
  title: "Women’s Development",
  url: `${leaderShipProgrammes.url}womens-leadership/`,
};

let openLeadershipProgrammes: NavPage = {
  title: "Open Programmes",
  url: `${leaderShipProgrammes.url}open-programmes/`,
};

let caseStudies: NavPage = {
  title: "Case Study",
  url: `${leaderShipProgrammes.url}case-study/`,
};

let executiveShipSeniorLeaders: NavPage = {
  title: "Senior Leaders",
  url: `${executiveCoaching.url}senior-leaders/`,
};

let executiveTeamCoaching: NavPage = {
  title: "Team Coaching",
  url: `${executiveCoaching.url}team-coaching/`,
};

let executiveCaseStudies: NavPage = {
  title: "Case Studies",
  url: `${executiveCoaching.url}case-studies/`,
};

let boardConsulting: NavPage = {
  title: "Board Consulting",
  url: "/board-consulting/",
};

let boardMeetingFacilitation: NavPage = {
  title: "Meeting Facilitation",
  url: `${leaderShipProgrammes.url}meeting-facilitation/`,
};

let boardConferenceSpeaking: NavPage = {
  title: "Conference Speaking",
  url: `${leaderShipProgrammes.url}conference-speaking/`,
};

overview.innerPages = [worldWide, dubai, aboutUs];

executiveCoaching.innerPages = [
  executiveShipSeniorLeaders,
  executiveTeamCoaching,
  executiveCaseStudies,
];

leaderShipProgrammes.innerPages = [
  leaderShipSeniorLeaders,
  womensLeadershipProgrammes,
  openLeadershipProgrammes,
  boardMeetingFacilitation,
  boardConferenceSpeaking,
  caseStudies,
];

// boardConsulting.innerPages = [
//   boardMeetingFacilitation,
//   boardConferenceSpeaking,
// ];

var coaches: NavPage = {title: "Coaches", url: "/coaches/"};
let resources: NavPage = {title: "Resources", url: "/resources/"};
let contact: NavPage = {title: "Contact", url: "/contact/"};

export default [
  overview,
  leaderShipProgrammes,
  executiveCoaching,
  coaches,
  resources,
  contact,
];
