import React from "react";
interface props {
  style?: React.CSSProperties;
  class?: string;
  justify?: string;
  align?: string;
}
const Row: React.FC<props> = (props) => {
  let className = "row";
  if (props.justify) {
    className += ` justify-content-${props.justify}`;
  }

  if (props.align) {
    className += ` align-items-${props.align}`;
  }

  if (props.class) {
    className += ` ${props.class}`;
  }

  return (
    <div className={className} style={props.style}>
      {props.children}
    </div>
  );
};
export default Row;
